<script setup lang="ts">
const testimonials = [
  {
    name: 'Gaurav Sabharwal',
    designation: 'Co-Founder, CEO',
    company: 'JOP',
    review:
      'ZapScale addresses our most important customer success needs. The solution is extremely intuitive, lightning fast to adopt and adds tremendous customer visibility.',
    rating: 5,
    image: '',
  },
  {
    name: 'Bhanupratap Rana',
    designation: 'Customer Success Manager',
    company: 'JOP',
    review:
      "ZapScale allows complete visibility on the customers' portfolio by collating product usage and other information from external systems. Easy to set-up playbooks help the CSMs to structure and prioritise key CS tasks.",
    rating: 5,
    image: '',
  },
  {
    name: 'Simarjeet Singh',
    designation: 'Software Test Engineer',
    company: 'Zoconut',
    review:
      'With ZapScale, I have the flexibility to tailor my dashboard according to different customer segments. This feature is truly remarkable. I can make my customer engagements more targeted and personalized which helps to enhance the overall customer experience.',
    rating: 5,
    image: '',
  },
  {
    name: 'Neeraj Singal',
    designation: 'Chief Executive Officer',
    company: 'Taggbox',
    review:
      'Zapscale is assisting our CSM team in managing customers effectively. We can measure the health, popular features, and overall performance of any account.',
    rating: 5,
    image: '',
  },
  {
    name: 'Sajal Mishra',
    designation: 'Customer Success Manager',
    company: 'Taggbox',
    review:
      'ZapScale empowers our organization with a centralized repository of customer data and essential analytics, leading to heightened operational efficiency. ZapScale makes our lives easier.',
    rating: 5,
    image: '',
  },
  {
    name: 'Sreeram Raj',
    designation: 'Customer Success Manager',
    company: 'WeGoT Utility Solutions',
    review:
      "ZapScale saves me a lot of time by consolidating all the customer data in one place and sharing it in a format that allows me to take action effectively. As a result, I'm able to have a better hold over my customer portfolio.",
    rating: 5,
    image: '',
  },
  {
    name: 'Shanmuga Priya VS',
    designation: 'Customer Success Manager',
    company: 'WEGoT Utility Solutions',
    review:
      'The biggest benefit that we got from Zapscale is customer activity tracking. Before Zapscalewe use to drop of a few balls here and there, but now we have complete control over it, and this has helped us improve our customer experience.',
    rating: 5,
    image: '',
  },
  {
    name: 'Thooyavan Manivaasakar',
    designation: 'Vice President Of Engineering',
    company: 'Survey2Connect',
    review:
      'ZapScale is a great tool to build your CS strategy around. Over the past year of using it, we have discovered its immense value in efficiently managing our customer success efforts. It has proven to be a highly useful asset, empowering us to deliver exceptional support and ensure the success of our valued customers.',
    rating: 5,
    image: '',
  },
  {
    name: 'Tanya Gupta',
    designation: 'Customer Success Executive',
    company: 'Survey2Connect',
    review:
      'ZapScale has elevated our customer success to new heights through its in-depth customer visibility and thorough analysis of our customer data. Their support is impressively responsive, and we’ve always received prompt and highly effective solutions.',
    rating: 5,
    image: '',
  },
  {
    name: 'Purvasha Gupta',
    designation: 'Customer Success Manager',
    company: 'Survey2Connect',
    review:
      "ZapScale empowers Customer Success teams to efficiently oversee their portfolio. With ZapScale's intuitive dashboards, we can easily identify potential risks and opportunities, enabling us to provide proactive support for our valued customers.",
    rating: 5,
    image: '',
  },
  {
    name: 'Priyesh Ghamandi',
    designation: 'VP, Product',
    company: 'XEBO.ai',
    review:
      'We have been able to build our CS around ZapScale. Zapscale helps us to setup our process better for growth and faster fr our cs department.',
    rating: 5,
    image: '',
  },
  {
    name: 'Shreekumar Nair',
    designation: 'Senior Customer Success Manager',
    company: 'XEBO.ai',
    review:
      'ZapScale gives complete visibility on all of my customer profiles and activities. Having all the required information in a single click really helps to improve the overall engagement and gives the ability to drive a better CS strategy.',
    rating: 5,
    image: '',
  },
];
</script>
<template>
  <div class="pb-8 md:pb-20">
    <div class="container mx-auto my-10">
      <div class="flex flex-col gap-x-16 max-md:px-5 md:flex-row md:items-center md:justify-center">
        <div>
          <span class="mx-auto mb-4 block w-fit text-base font-normal text-[#19274D]">TESTIMONIALS</span>
          <span class="mx-auto block w-fit font-merriweather text-3xl font-bold text-[#19274D] xl:text-[35px]"
            >Trusted by Many</span
          >
        </div>
        <!-- <span class="block text-lg font-normal leading-[27px] text-[#19274D] md:w-[489px]">
          No more waiting for months to start tracking your customer and user health. Get up and running on ZapScale the
          day you launch.
        </span> -->
      </div>
    </div>
    <div class="relative hidden w-full md:flex">
      <div class="fade-right absolute left-0 z-10 h-full w-16 bg-white md:w-20 lg:w-[200px]"></div>
      <div class="slider relative m-auto h-[337px] w-full overflow-hidden">
        <div class="slide-track flex gap-x-4 md:gap-x-6">
          <div
            class="flex h-[337px] w-[359px] items-center justify-center rounded-2xl border border-[#E5E5E6] bg-[#EFF7FD]"
            v-for="(testimonial, index) in [...testimonials, ...testimonials]"
            :key="`testimonial+${index}`"
          >
            <div class="relative h-full p-4 md:p-7">
              <p class="text-[1.313rem] font-bold">{{ testimonial.name }}</p>
              <p class="mt-2 text-sm font-normal text-[#6A6A6A]">{{ testimonial.designation }}</p>
              <p class="mt-2 text-sm font-normal text-[#6A6A6A]">{{ testimonial.company }}</p>
              <p class="mt-4 whitespace-pre-wrap break-words text-sm">
                {{ testimonial.review }}
              </p>
              <div class="mt-3 flex space-x-1">
                <Icon name="ph:star-fill" v-for="i in testimonial.rating" :key="i" color="#66B774" class="h-5 w-5" />
                <Icon name="ph:star" v-for="i in 5 - testimonial.rating" :key="i" color="#66B774" class="h-5 w-5" />
              </div>
              <!-- <NuxtImg
                loading="lazy"
                src="/images/g2-logo.svg"
                class="absolute bottom-4 right-4 h-7 w-7 md:bottom-7 md:right-7"
                alt="g2-logo"
                width="28"
                height="28"
              /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="fade-left absolute right-0 z-10 h-full w-16 bg-white md:w-20 lg:w-[200px]"></div>
    </div>

    <div class="px-3 md:hidden">
      <Swiper
        :modules="[SwiperNavigation]"
        :slides-per-view="1"
        :loop="true"
        :navigation="{
          nextEl: '.nextArrow',
          prevEl: '.prevArrow',
        }"
        :creative-effect="{
          prev: {
            shadow: false,
            translate: ['-100%', 0, -1],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }"
      >
        <SwiperSlide v-for="(testimonial, index) in testimonials" :key="testimonial.review" class="p-2">
          <div class="relative h-[337px] rounded-2xl border border-[#E5E5E6] bg-[#EFF7FD] p-4">
            <p class="text-[1.313rem] font-bold">{{ testimonial.name }}</p>
            <p class="mt-2 text-sm font-normal text-[#6A6A6A]">{{ testimonial.designation }}</p>
            <p class="mt-2 text-sm font-normal text-[#6A6A6A]">{{ testimonial.company }}</p>
            <p class="mt-4 whitespace-pre-wrap break-words text-sm">
              {{ testimonial.review }}
            </p>
            <div class="mt-3 flex space-x-1">
              <Icon name="ph:star-fill" v-for="i in testimonial.rating" :key="i" color="#66B774" class="h-5 w-5" />
              <Icon name="ph:star" v-for="i in 5 - testimonial.rating" :key="i" color="#66B774" class="h-5 w-5" />
            </div>
            <NuxtImg
              loading="lazy"
              src="/images/g2-logo.svg"
              class="absolute bottom-4 right-4 h-7 w-7"
              alt="g2-logo"
              width="28"
              height="28"
            />
          </div>
        </SwiperSlide>

        <div class="mx-auto flex w-1/4 items-center justify-center gap-x-2">
          <Icon class="prevArrow" name="iconamoon:arrow-left-2-light" color="#3a3a3c" width="48" height="48"></Icon>
          <Icon class="nextArrow" name="iconamoon:arrow-right-2-light" color="#3a3a3c" width="48" height="48"></Icon>
        </div>
      </Swiper>
    </div>
  </div>
</template>

<style scoped lang="scss">
$animationSpeed: 35s;

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 24));
  }
}

.slide-track {
  animation: scroll $animationSpeed linear infinite;
  width: calc(359px * 24);

  @media only screen and (min-width: 1280px) {
    &:hover {
      animation-play-state: paused;
    }
  }
}

.fade-right {
  --mask-right: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0) 0
    )
    100% 50% / 100% 100% repeat-x;

  -webkit-mask: var(--mask-right);
  mask: var(--mask-right);
}
.fade-left {
  --mask-left: linear-gradient(
      to left,
      rgba(0, 0, 0, 1) 0,
      rgba(0, 0, 0, 1) 40%,
      rgba(0, 0, 0, 0) 95%,
      rgba(0, 0, 0, 0) 0
    )
    100% 50% / 100% 100% repeat-x;

  -webkit-mask: var(--mask-left);
  mask: var(--mask-left);
}
</style>
